
  <!-- 法音 -->
  <template>
    <div class="word">
        <div v-for="(item,index) in length" :key="index" style="width:100%;">
            <table border="1" cellspacing="0">
                <tr class="ta-l">
                    <th colspan="2" style="width:50%">
                        <div class="title">{{contentList[2*index].cate_name}}</div>
                        <div
                            class="brief"
                            v-show="contentList[2*index].cate_brief"
                        >({{contentList[2*index].cate_brief}})</div>
                    </th>
                    <th colspan="2" style="width:50%">
                        <div class="title">{{contentList[2*index+1].cate_name}}</div>
                        <div
                            class="brief"
                            v-show="contentList[2*index+1].cate_brief"
                        >({{contentList[2*index+1].cate_brief}})</div>
                    </th>
                </tr>
                <tr v-for="(item1,index1) in contentList[2*index].lists.length" :key="index1">
                    <td class="numTitle">
                        <div v-if="contentList[2*index].lists[index1]">{{index1+1}}</div>
                    </td>
                    <td
                        class="contentTitle"
                        @click="linkToSongDetail(contentList[2*index].lists[index1])"
                    >
                        <div
                            v-if="contentList[2*index].lists[index1]"
                        >{{contentList[2*index].lists[index1].audio_title}}</div>
                    </td>

                    <td class="numTitle">
                        <div v-if="contentList[2*index+1].lists[index1]">{{index1+1}}</div>
                    </td>
                    <td
                        @click="linkToSongDetail(contentList[2*index+1].lists[index1])"
                        class="contentTitle"
                    >
                        <div
                            v-if="contentList[2*index+1].lists[index1]"
                        >{{contentList[2*index+1].lists[index1].audio_title}}</div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="line"></div>
    </div>
</template>

<script>
import { getCateList, getSoundCate } from "../../api/apis";
export default {
    name: "",

    data() {
        return {
            contentList: [
                {
                    id: 30,
                    cate_name: "安祥禅第一集",
                    cate_brief: "123",
                    lists: [{ id: 14, audio_title: "法大师傅士大夫" }]
                },
                {
                    id: 31,
                    cate_name: "安祥禅第二集",
                    cate_brief: "123",
                    lists: []
                },
                {
                    id: 32,
                    cate_name: "安祥禅第三集",
                    cate_brief: "123",
                    lists: []
                },
                {
                    id: 33,
                    cate_name: "其他",
                    cate_brief: "",
                    lists: [{ id: 14, audio_title: "法大师傅士大夫" }]
                }
            ],
            length: 0 //记录数据条数除以2取整
        };
    },

    methods: {
        //歌曲详情
        linkToSongDetail(item) {
			window.open(this.$BaseURL+'/musicDetail?name=frenchp&id='+item.id);
            // this.$router.push({
            //     path: "/musicDetail",
            //     query: { name: "frenchp", id: item.id }
            // });
        },
        //初始化数据
        initData() {
            let list = this.contentList;
            this.length = Math.ceil(list.length / 2);

            //处理基数条数据的时候
            if (list.length % 2 === 1) {
                list[list.length] = { lists: [] };
                list[list.length].lists.length =
                    list[list.length - 1].lists.length;
            }
            //处理基偶条数据时，比较数据，采用最长的数据条数
            for (let i = 0; i < this.length; i++) {
                if (list[2 * i].lists.length > list[2 * i + 1].lists.length) {
                    list[2 * i + 1].lists.length = list[2 * i].lists.length;
                } else {
                    list[2 * i].lists.length = list[2 * i + 1].lists.length;
                }
            }
        }
    },
    created() {
        // this.initData();
        getCateList().then(res => {
            let id = res[1][5].id;
            getSoundCate(id).then(res => {
                this.contentList = res;
                
                this.initData();
            });
        });
    }
};
</script>

<style lang="stylus" scoped>
.ta-l {
    text-align: left;
}

.pl5 {
    padding-left: 5px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #ACACAC;
}

.word {
    width: 750px;
    margin: 20px 10px;

    .title {
        font-size: 15px;
        font-weight: 600;
    }
}

table, td, tr, th {
    border: 1px solid #ACACAC;
    border-bottom: 0px solid #ACACAC;
}

table {
    width: 100%;
    font-size: 15px;

    th {
        line-height: 26px;
        height: 50px;
        padding-left: 15px;
        text-align: center;

        .title {
            margin-top: 5px;
            font-weight: 600;
        }

        .brief {
            font-weight: 400;
            font-size: 14px;
        }
    }

    td {
        line-height: 30px;
        // cursor: pointer;
    }

    .numTitle {
        width: 60px;
    }

    .contentTitle {
        width: 378px;
        padding-left: 5px;
        text-align: left;
        cursor: pointer;
    }

    .contentTitle:hover {
        color: #1482d9;
    }
}
</style>